.create_fond_page {
  margin: 20px;
}
.create_fond_page form{
  padding: 20px;
  display: flex;
    flex-direction: column;
  gap: 20px;
}
.create_fond_page button {
  width: fit-content;

}